var $el;
var supportPageOffset = window.pageXOffset !== undefined;

function initLoop () {
	$el = document.querySelector('.bg-lement__svg');
	if (window.requestAnimationFrame && $el) window.requestAnimationFrame(loop);
}

function loop () {
	var y = supportPageOffset ? window.pageYOffset : document.documentElement.scrollTop;
	var rotate = (y / window.innerHeight) * 15;
	$el.style.transform = 'rotate(' + rotate + 'deg)';
	window.requestAnimationFrame(loop);
}

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', initLoop);
} else {
	initLoop();
}
