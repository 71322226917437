import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg';
import AOS from 'aos';

import '../css/app.pcss';

AOS.init({
	// startEvent: 'lazyloaded',
	offset: 100,
	duration: 250,
	easing: 'ease-out'
});

if (!('object-fit' in document.createElement('a').style)) {
	require('lazysizes/plugins/object-fit/ls.object-fit');
}

Element.prototype.hasClass = function (className) {
	return this.className && new RegExp('(^|\\s)' + className + '(\\s|$)').test(this.className);
};

// Global
var toggleDropDown = document.getElementById('toggleDropDown');
var closeDropDown = document.getElementById('closeDropDown');
var dropDownMenu = document.querySelector('#dropDownMenu');
var mainFooter = document.querySelector('.main-footer');

toggleDropDown.addEventListener('click', function (evt) {
	if (!dropDownMenu.hasClass('visible')) {
		dropDownMenu.classList.add('visible');
		mainFooter.classList.remove('invisible');
	} else {
		dropDownMenu.classList.remove('visible');
		mainFooter.classList.add('invisible');
	}
});

closeDropDown.addEventListener('click', function (evt) {
	dropDownMenu.classList.remove('visible');
	mainFooter.classList.add('invisible');
});

// Article categories
var categories = document.querySelector('.categories');
if (categories) {
	categories.addEventListener('change', (event) => {
		window.location = event.target.value;
	});
}

// Add class do parent for lazyloaded images
document.addEventListener('lazyloaded', function (event) {
	var $target = event.target;
	var $parent = $target.parentNode;

	$parent.classList.add('loaded');

	AOS.refresh();
});

// Check elements to create sizes for srcset
window.onload = window.lazySizes.autoSizer.checkElems;

document.addEventListener('DOMContentLoaded', function () {
	let videosArray = document.querySelectorAll('.text-module iframe[src^="https://player.vimeo.com"], .text-module iframe[src^="//player.vimeo.com"], .text-module iframe[src^="https://www.youtube.com/embed/"], .text-module iframe[src^="//www.youtube.com/embed/"]');
	videosArray = Array.prototype.slice.call(videosArray);

	videosArray.map((elem) => {
		let videoHeight = elem.height !== '' ? elem.height : elem.style.height.replace('px', '');
		let videoWidth = elem.width !== '' ? elem.width : elem.style.width.replace('px', '');

		elem.dataset.aspectRatio = videoHeight / videoWidth;
		elem.removeAttribute('height');
		elem.removeAttribute('width');
		elem.removeAttribute('style');
	});

	function resizeVideo () {
		videosArray.map((elem) => {
			if (elem.parentNode) {
				let newWidth = elem.parentNode.offsetWidth;
				elem.width = newWidth;
				elem.height = newWidth * elem.dataset.aspectRatio;
			}


			console.log(elem);
		});
	}

	window.addEventListener('resize', function (evt) {
		resizeVideo();
	});

	resizeVideo();
});

if (module.hot) {
	module.hot.accept();
}
